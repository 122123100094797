import "../styles/index.scss";
import $ from "jquery";
import {getJson} from "./getJson";
import {MenuItem} from "./MenuItemsClass";
import initBuilderTools from "./helpers";
import {
    addListenersOnButtons,
    calcPrice,
    checkUrlForLeykaEidh,
    disableBedFeet,
    hideBackgroundButton,
    includeKrevatiInPrice,
    includeLeykaInPrice, magnify,
    scrollMenuToTop,
    setListenersOnRebuild,
    showHideChat,
    showHidePillows,
    submitAiForm,
    showHideGlass
} from "./UIfunctions";
import {
    getActiveStateProps,
    setMinMaxValueAccordingToBase,
    setMinMaxValueAccordingToHeadboard,
} from "./dimensionsFunctions";
import {createSelections, setListenerForSelecetions} from "./pureSelect";
import {hideLoader, useLoader} from "./loader";
import initShowBackgroundListeners from "./backgroundsFunctions";
import {BASE_WITHOUT_BEDFEET, BEDS_WITHOUT_BEDFEET} from "./consts";
import {constructSelectionsOnload} from "./selections";
import "animate.css";
import {initAddToCartButton} from "./addToCart";
import setDimensionsForOrder from "./setDimensionsForOrder";
import {activeParentClosure} from "./activeParentClosure";
import {getActiveBaseAndBedCodeOnFirstFetch} from "./getActiveBaseAndBedCodeOnFirstFetch";
import replaceImages from "./imagesTools";

export const sessionPrefix = JSON.parse(
        document.getElementById("sessionPrefix").textContent
    ),
    layoutType = JSON.parse(document.getElementById("layoutType").textContent),
    layoutName = JSON.parse(document.getElementById("layoutName").textContent),
    allPropertyValues = JSON.parse(
        document.getElementById("allPropertyValues").textContent
    ),
    isUserLoggedIn = JSON.parse(
        document.getElementById("isUserLoggedIn").textContent
    ),
    orderId = JSON.parse(document.getElementById("orderId").textContent);

export let baseId = null,
    headboardId = null;

export let activeParentCodes = null;

$(document).ready(async function (e) {
    let firstFetch = await getJson(e, "all", 0, false, layoutType, layoutName),
        layoutState = firstFetch.layoutState;
    global.menuData = firstFetch.menuData;
    global.layoutState = layoutState;
    global.menuStateNames = firstFetch.menuStateWithPropNames;
    let dimensions = firstFetch.dimensions;
    const codes = getActiveBaseAndBedCodeOnFirstFetch({
        data: firstFetch.menuStateWithPropNames,
    });
    activeParentCodes = activeParentClosure({codes});

    let isBed = firstFetch.isBedsOn;
    // firstFetch.menuData.forEach((el) => {
    //     if (el.name === BEDS) {
    //         if (el.active) isBed = true;
    //     }
    // });
    document.querySelector(".lds-ring").style.display = "none";


    if (document.querySelector("#pillow-option-1").value === firstFetch.pillowsRadioOption) {
        document.querySelector("#pillow-option-1").checked = true;
    } else {
        document.querySelector("#pillow-option-2").checked = true;
    }
    document.querySelector("#leyka-checkbox").checked = firstFetch.lefkaEidhCheckboxesState.leyka_eidh_general_price_checkbox;
    document.querySelector("#krevati-checkbox").checked = firstFetch.lefkaEidhCheckboxesState.bed_general_price_checkbox;


    Object.values(firstFetch.menuStateWithPropNames).forEach(({b}) => {
        if (isBed) {
            if (b?.name)
                if (BEDS_WITHOUT_BEDFEET.includes(b?.name.toLowerCase())) {
                    $("#bed-foot").addClass("disabled");
                }
        } else {
            if (b?.name)
                if (BASE_WITHOUT_BEDFEET.includes(b?.name.toLowerCase())) {
                    $("#bed-foot").addClass("disabled");
                }
        }
    });

    // Load the layoutState in the Divs.
    replaceImages(Object.values(layoutState));

    await global.menuData.forEach((el) => {
        let menuItem = new MenuItem(
            el.id,
            el.active,
            el.showInFrontend?.name ? el.showInFrontend.name : el.name,
            el.category,
            el.structure,
            el.thumbUrl,
            el.images,
            el.subCategoryItems,
            el.showInFrontend,
            el.dontShowInFrontend
        );
        menuItem.buildMenuItem();
    });

    // document.querySelector("#leyka-checkbox").addEventListener("change", (e) => {
    //     if (e.target.checked) {
    //         document.querySelector(".leyka-popover").classList.add("show");
    //     } else {
    //         document.querySelector(".leyka-popover").classList.remove("show");
    //     }
    // });

    // document.querySelector("#close-leyka-popover").addEventListener("click", (e) => {
    //     document.querySelector(".leyka-popover").classList.remove("show");
    // });

    // Get the elements
    // pop up popover popup
    // const popupContainer = document.getElementById('popup-container');
    // const popupCloseButton = document.getElementById('popup-close-button');
    // const popupCover = document.getElementById('popup-cover');

    // // Function to close the popup
    // function closePopup() {
    //     popupContainer.classList.remove('popup-open');
    //     popupContainer.classList.add('popup-close'); // Optional: Use this class for transitions if needed
    //     popupCover.style.display = 'none'; // Hide the cover
    // }

    // function openPopup() {
    //     popupContainer.classList.add('popup-open');
    //     popupCover.classList.add('popup-open');
    // }


    // // Add event listeners for closing the popup
    // popupCloseButton.addEventListener('click', closePopup);
    // popupCover.addEventListener('click', closePopup);


    document.querySelector("#property-selections").setAttribute("multiple", "");
    useLoader();
    initBuilderTools();
    hideBackgroundButton();
    addListenersOnButtons();
    setListenersOnRebuild();
    activeParentCodes.setMaxBedDimensions(dimensions?.bedWidth, dimensions?.bedLength);
    activeParentCodes.setBedsOnly(firstFetch.isBedsOn);
    setMinMaxValueAccordingToHeadboard();
    setMinMaxValueAccordingToBase();
    getActiveStateProps();
    initShowBackgroundListeners();
    initAddToCartButton();
    await createSelections("#property-selections", allPropertyValues);
    setListenerForSelecetions();
    hideLoader();
    includeLeykaInPrice();
    includeKrevatiInPrice();
    scrollMenuToTop();
    setDimensionsForOrder({dimensions});
    await calcPrice({connectRange: document.querySelector("#connect-range")});
    constructSelectionsOnload();
    disableBedFeet();
    showHideChat();
    submitAiForm();
    showHidePillows();
    checkUrlForLeykaEidh(firstFetch.lefkaEidhCheckboxesState.diakosmitika_maksilaria);
    //magnify("magnify-image", 1.5);
    // openPopup();

});
